<template>
  <div class="pagination">
    <!-- ------------------------------ 上-->
    <!-- 当前第一页时，上一页失效 -->
    <button :disabled="pageNo == 1" @click="$emit('getPageNo', pageNo - 1)">
      <i class="el-icon-arrow-left"></i>
    </button>
    <!-- 起始数字大于1，显示1，起始数字就是1，隐藏1 -->
    <button
      v-show="startNumAndEndNum.start > 1"
      @click="$emit('getPageNo', 1)"
      :class="{ active: pageNo == 1 }"
    >
      1
    </button>
    <!-- 起始数字大于2，显示...，起始数字是1/2，隐藏... -->
    <button v-show="startNumAndEndNum.start > 2">...</button>

    <!-- ------------------------------ 中-->
    <!-- 遍历出1~10，只需要6~10，保留start以后的数字 -->
    <button
      v-for="(page, index) in startNumAndEndNum.end"
      :key="index"
      v-show="page >= startNumAndEndNum.start"
      @click="$emit('getPageNo', page)"
      :class="{ active: pageNo == page }"
    >
      {{ page }}
    </button>

    <!-- ------------------------------ 下-->
    <!-- 结束数字小于总页数-1(30)，也就是end=29,显示... -->
    <button v-show="startNumAndEndNum.end < totalPage - 1">...</button>
    <!-- 结束数字小于总页数，显示总页数31 -->
    <button
      v-show="startNumAndEndNum.end < totalPage"
      @click="$emit('getPageNo', totalPage)"
      :class="{ active: pageNo == totalPage }"
    >
      {{ totalPage }}
    </button>
    <button
      :disabled="pageNo == totalPage"
      @click="$emit('getPageNo', pageNo + 1)"
    >
      <i class="el-icon-arrow-right"></i>
    </button>
    <!-- ------------------------------ -->
    <button class="total">共{{ total }}条</button>
  </div>
</template>

<script>
export default {
  name: "Pagination",
  props: ["pageNo", "pageSize", "total", "continues"],
  // 总页数
  computed: {
    totalPage() {
      return Math.ceil(this.total / this.pageSize);
    },
    // 连续页面起始数字和结束数字
    startNumAndEndNum() {
      const { continues, totalPage, pageNo } = this;
      // 变量存储起始数字和结束数字
      let start = 0,
        end = 0;
      // 连续页码数至少是5，特殊情况（少于5个）
      if (continues > totalPage) {
        start = 1;
        end = totalPage;
      } else {
        // 总页数大于连续页码数,比如当前第8页，分别是6,7,8,9,10
        start = pageNo - parseInt(continues / 2);
        end = pageNo + parseInt(continues / 2);
        // bug:如果当前是第1页，则-1,0,1,2,3，会出现-1，应该是1,2,3,4,5
        // bug:如果当前是第2页，则0,1,2,3,4会出现0，也应该是1,2,3,4,5
        // 出现0或负数处理
        if (start < 1) {
          start = 1;
          end = continues;
        }
        // 结束数字大于总页数，比如当前是31页，则29,30,31,32,33,33>总页数31,应该是27,28,29,30,31
        // 当前是30页，则28,29,30,31,32,32还是>31,应该是27,28,29,30,31
        if (end > totalPage) {
          end = totalPage;
          start = totalPage - continues + 1;
        }
      }
      return { start, end };
    },
  },
};
</script>

<style lang="less" scoped>
/* 大于768（电脑） */
@media screen and (min-width: 1300px) {
  .pagination {
    text-align: center;
    button {
      margin: 0 5px;
      background-color: #e7e2e2;
      background: #fff;
      // color: #606266;
      color: black;
      outline: none;
      border-radius: 6px;
      padding: 0 4px;
      vertical-align: top;
      display: inline-block;
      font-size: 16px;
      min-width: 40px;
      height: 40px;
      line-height: 40px;
      cursor: pointer;
      box-sizing: border-box;
      text-align: center;
      border: solid #f4f4f5 1px;

      &[disabled] {
        color: #c0c4cc;
        cursor: not-allowed;
      }

      &.active {
        cursor: not-allowed;
        background-color: #409eff;
        color: #fff;
      }
    }

    .total{
      margin-left: 30px
    }
  }
}
/* 小于768（手机） */
@media screen and (max-width: 1300px) {
  .pagination {
    // border: solid blue 1px;
    text-align: center;
    width: 100%;
    button {
      margin: 0 .075rem;
      background: #fff;
      color: black;
      outline: none;
      border-radius: .15rem;
      padding: 0 .1rem;
      vertical-align: top;
      display: inline-block;
      font-size: .35rem;
      min-width: .75rem;
      height: .75rem;
      line-height: .75rem;
      box-sizing: border-box;
      text-align: center;
      border: solid #f4f4f5 1px;

      &[disabled] {
        color: #c0c4cc;
        cursor: not-allowed;
      }

      &.active {
        cursor: not-allowed;
        background-color: #409eff;
        color: #fff;
      }
    }

    .total{
      display: none;
    }
  }
}
</style>