<template>
  <div class="container">
    <!--meta中show为true的路由组件添加header类名， -->
    <!-- <TopNav :class="{topNav:$route.meta.show}"/> -->
    <TopNav />
    <keep-alive exclude="NewsDetail,News,ProductDetail">
      <router-view></router-view>
    </keep-alive>
    <Footer />
    <AsideTool />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import TopNav from "@/components/TopNav";
import Footer from "@/components/Footer";
import AsideTool from "@/components/AsideTool";
export default {
  components: { Footer, AsideTool, TopNav },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["site_head"]),
    title() {
      return this.site_head.sitenav_site_title;
    },
    keywords() {
      return this.site_head.sitenav_site_keywords;
    },
    description() {
      return this.site_head.sitenav_site_description;
    },
  },
  methods: {
    // ...mapActions('home',['getHome'])
  },
  mounted() {
    // 调用getCommon，告诉vuex发请求获取common数据
    this.$store.dispatch("getCommon");
    // 首页
    this.$store.dispatch("getHome");
    
    this.$store.dispatch("getSlider");
    // 招商加盟（事业背景）
    this.$store.dispatch("getBusiness");
    // 关于我们
    this.$store.dispatch("getAbout");
  },
  nextTick() {
    document
      .querySelector('meta[name="keywords"]')
      .setAttribute("content", this.keywords);
    document
      .querySelector('meta[name="description"]')
      .setAttribute("content", this.description);
    document.title = this.title;
    
  },
  // 路由跳转滚动条重置位置
  watch: {
    $route() {
      window.scrollTo(0, 0);
    },
  },
};
</script>

<style lang="less">
// 引入字体
// @font-face {
//   font-family: 'my';
//   src: url('@/assets/fonts/my.otf') format('truetype');
// }

.container {
  // width: 1920px;
  .topNav {
    position: absolute;
    z-index: 2;
  }
}
</style>
