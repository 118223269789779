<template>
  <div class="container">
    <div class="defaultTool">
      <div class="defaultIcon">
        <i class="iconfont icon-duanxin"></i>
      </div>
      <div class="defaultIcon">
        <i class="iconfont icon-weixiu"></i>
      </div>
      <div class="defaultIcon">
        <i class="iconfont icon-arrow-alt-to-top"></i>
      </div>
      <div class="hoverTool">
        <div class="hoverIcon">
          <i class="iconfont icon-duanxin"></i>
          <span>详情咨询</span>
          <div class="hoverTip">联系电话:18958156661</div>
        </div>
        <div class="hoverIcon">
          <i class="iconfont icon-weixiu"></i>
          <span>售后服务</span>
          <div class="hoverTip">联系电话:0571-87032990</div>
        </div>
        <div class="hoverIcon" @click="ToTop">
          <i class="iconfont icon-arrow-alt-to-top"></i>
          <span>返回顶部</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AsideTool",
  data() {
    return {};
  },
  methods: {
    ToTop() {
      window.scrollTo(0, 0);
    }
  }
}
</script>

<style lang="less" scoped>
/* 大于768（电脑） */
@media screen and (min-width: 1300px) {
  .container {
    .defaultTool {
      cursor: pointer;
      position: fixed;
      right: 0;
      top: 700px;
      z-index: 99;
      // border: solid red 1px;
      height: 165px;
      width: 60px;
      background: rgb(255, 255, 255);
      box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
      border-radius: 8px;
      // overflow: hidden;
      .defaultIcon {
        box-sizing: border-box;
        height: 55px;
        // border: solid blue 1px;
        text-align: center;
        padding-top: 15px;
        .iconfont {
          font-size: 24px;
          color: #1a7dff;
        }
      }

      .hoverTool {
        width: 0;
        width: 138px;
        height: 165px;
        // background: rgba(26, 125, 255);
        background: #fff;
        border-radius: 8px;

        position: absolute;
        // right: 0;
        right: -138px;
        top: 0;
        .hoverIcon {
          position: relative;
          box-sizing: border-box;
          // border: solid red 1px;
          height: 55px;
          padding-top: 15px;
          padding-left: 18px;
          // text-align: center;
          // line-height: .375rem;
          .iconfont {
            color: #1a7dff;
            font-size: 24px;
            margin-right: 20px;
            // float: left;
            // border: solid red 1px;
          }
          span {
            // display: block;
            font-size: 14px;
            // float: left;
            // border: solid red 1px;
            height: 30px;
            line-height: 30px;
            vertical-align: top;
          }
          .hoverTip {
            display: none;
            position: absolute;
            left: -238px;
            top: -14px;
            width: 238px;
            height: 82px;
            // border: solid red 1px;
            background: url(./hoverTip.png) no-repeat 0 0 / cover;
            font-size: 16px;
            color: #333333;
            // text-align: center;
            box-sizing: border-box;
            padding: 29px 0 0 29px;
          }
          &:hover {
            background: #1a7dff;
          }
          &:hover .iconfont {
            color: #fff;
          }
          &:hover span {
            color: #fff;
          }
          &:hover .hoverTip {
            display: block;
          }
        }
      }
      &:hover {
        height: 0;
      }
      &:hover .hoverTool {
        // border: solid red 1px;
        // width: 1.725rem;
        right: 0;
        transition: 0.3s linear;
      }
    }
  }
}
/* 小于768（手机） */
@media screen and (max-width: 1300px) {
  .container {
    display: none;
  }
}
</style>
