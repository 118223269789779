// 统一管理api
// 引入二次封装的axios请求
import requests from './request'

// 在组件中需要发请求时，调用这里封装好的请求函数，避免重复书写请求代码

// 1.common请求函数
export const reqCommon = () => {
    return requests({
        method: 'get',
        // 因为封装请求时写了基础路径，这里省略/api
        url: '/common'
    })
}

export const reqSlider = () => {
    return requests({
        method: 'get',
        url: '/slide-list'
    })
}

// 2.请求home数据
export const reqHome = () => {
    return requests({
        method: 'get',
        url: 'channel-content?channel_id=18'
    })
}

// 3.产业合作加盟申请+招商加盟-加盟申请 +方案下载申请表单（表单提交）
export const reqJoin = (url,data) => {
    return requests.post(url,data)
}

// 4.新闻动态页面
export const reqNews = (query) =>{
    return requests({
        method: 'get',
        url:'/news',
        params:query
    })
}

// 5.新闻详情页面
export const reqNewsDetail = (query) => {
    return requests({
        method:'get',
        url:'/news-detail',
        params:query
    })
}

// 6.产品中心页面
export const reqProduct = () => {
    return requests({
        method: 'get',
        url: '/channel-content?channel_id=50'
    })
}

// 产品详情页
export const reqProductDetail = (query) => {
    return requests({
        method:'get',
        url:'/product-info',
        params:query
    })
}

// 产品详情服务条款
export const reqGuarantee = () =>{
    return requests({
        method: 'get',
        url:'/wxapplet/product-guarantee'
    })
}

// 7.周边配件页面
export const reqOtherProducts = (query) =>{
    return requests({
        method: 'get',
        url:'/product-list?channel_id=50',
        params:query
    })
}
// 7.解决方案页面
export const reqSolution = (query) =>{
    return requests({
        method: 'get',
        url:'/solution-list?channel_id=51',
        params:query
    })
}

// 8.定制服务页面
export const reqSpecial = () =>{
    return requests({
        method: 'get',
        url:'service-list?channel_id=52'
    })
}

// 9.招商加盟（事业背景）
export const reqBusiness = () => {
    return requests({
        method: 'get',
        url: '/channel-content?channel_id=24'
    })
}

// 10.关于我们
export const reqAbout = () => {
    return requests({
        method: 'get',
        url: 'channel-content?channel_id=37'
    })
}

// 11.产业合作页面
export const reqIndustrial = () => {
    return requests({
        method: 'get',
        url: 'channel-content?channel_id=28'
    })
}

// 12.招商加盟-加盟申请页面
export const reqJoinUs = () => {
    return requests({
        method: 'get',
        url: 'channel-content?channel_id=27'
    })
}

// 荣誉资质
export const reqHonorDetail = () => {
    return requests({
        method: 'get',
        url: 'honor?channel_id=39'
    })
}

// search请求
export const reqGetSearchInfo = (query) =>{
    return requests({
        method:'get',
        url:'/search-list',
        params:query
    })
}