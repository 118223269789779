import Vue from 'vue'
import Vuex from 'vuex'

import common from './common'
import home from './home'
import news from './news'
import product from './product'
import business from './business'
import about from './about'
import industrial from './industrial'
import search from './search'

Vue.use(Vuex)

export default new Vuex.Store({
  // 所有图片后台公共路径
  state:{
    // imgBaseUrl:'http://www.ysjjjy.com/uploads'
    imgBaseUrl:''
  },
  modules: {
    common,
    home,
    news,
    product,
    business,
    about,
    industrial,
    search
  }
})
