export default {
	install(Vue) {
		// console.log('插件被调用');
		Vue.directive('big', function(element, binding) {
				element.innerText = binding.value * 10
			}),
			Vue.directive('scroll', {
				inserted: function(element, binding) {
					let f = function(event) {
						if (binding.value(event, element)) {
							window.removeEventListener('scroll', f)
						}
					}
					window.addEventListener('scroll', f)
				}
			}),
			Vue.prototype.MyScroll = (event, element) => {
				// if (window.scrollY > 200) {
				//     element.setAttribute("style","opacity: 1; transform: translateY(-50px)")
				// }
				let pos = element.getBoundingClientRect()
				if (pos.top < 1080) {
					element.setAttribute(
						"style", "opacity: 1; transform: translateY(0);transition: 1s linear;")
				}
			},
			Vue.prototype.$isMobile = () => {
				var userAgentInfo = navigator.userAgent;

				var mobileAgents = [
					"Android",
					"iPhone",
					"SymbianOS",
					"Windows Phone",
					"iPad",
					"iPod",
				];

				var mobile_flag = false;

				//根据userAgent判断是否是手机
				for (var v = 0; v < mobileAgents.length; v++) {
					if (userAgentInfo.indexOf(mobileAgents[v]) > 0) {
						mobile_flag = true;
						break;
					}
				}

				var screen_width = window.screen.width;
				var screen_height = window.screen.height;

				//根据屏幕分辨率判断是否是手机
				if (screen_width < 500 && screen_height < 800) {
					mobile_flag = true;
				}

				return mobile_flag;
			},
			Vue.prototype.$swiperOptions = {
				initialSlide: 0, //设定初始化时显示的slide
				speed: 1000, //切换速度，切换一次所用时间
				loop: true, //循环
				autoplay: {
					delay: 3000, //切换间隔
					stopOnLastSlide: true,
				},
				navigation: {
					nextEl: ".swiper-button-next",
					prevEl: ".swiper-button-prev",
				},

				slidesPerView: "auto", //根据宽度设定同时显示的数量
				slidesOffsetBefore: 16, //slide与左边框的预设偏移量
			}
	}
}

// 1.创建并暴露插件
// 2.main.js引入并注册插件
// 3.在插件中定义全局指令
// 4.定义全局方法
// 5.在需要使用滚动指令的元素上绑定自定义指令：v-scroll="handleScroll"
// 6.定义方法：
// handleScroll(event, element){
//         this.MyScroll(event, element)
//     }
// 7.添加样式
// opacity: 0;
// transition: 1s linear;